<template>
  <div
    v-if="isLoading"
    class="d-flex h-100 align-items-center justify-content-center"
  >
    <img :src="require('@/assets/svg/spinner_black.svg')" />
  </div>
  <div v-else>
    <DonateModal
      v-if="donateModal"
      :social-project="item"
      @close="donateModal = false"
    ></DonateModal>

    <div class="hty-heading mb-3">
      {{ $t('socialProjects.overviewTitle') }}
    </div>

    <!-- Main -->
    <div class="row">
      <!-- Left side -->
      <div class="col-12 col-md overflow-hidden">
        <VueAgile :nav-buttons="false">
          <div class="slide" v-for="img in item.images" :key="img">
            <img
              :src="img"
              style="
                border-radius: 5px;
                object-fit: cover;
                width: 100%;
                height: 100%;
                max-height: 40vh;
              "
            />
          </div>
        </VueAgile>

        <small class="image-copyright">
          &copy; {{ item.organization.name }}
        </small>
      </div>

      <!-- Right side -->
      <div class="col-12 col-md">
        <!-- Project description -->
        <div>
          <h2 class="hty-heading mt-3 mt-md-0">
            {{ item.title }}
          </h2>

          <p class="mt-2 mb-1" v-html="item.description"></p>
        </div>

        <!-- Project goal -->
        <div>
          <h2 class="hty-heading mt-3 mt-md-0">Projektziel</h2>
          <p class="mt-2 mb-1" v-html="item.goal_description"></p>
        </div>
      </div>
    </div>

    <!-- Donate button -->
    <ButtonElement
      class="ml-auto mt-3"
      orange
      style="width: 150px"
      @click="donateModal = true"
    >
      {{ $t('socialProjects.donate') }}
    </ButtonElement>

    <!-- Organization -->
    <div class="row mt-3 py-4" style="background: rgba(229, 132, 19, 0.05)">
      <h2 class="hty-heading col-12 mb-3">
        {{ $t('socialProjects.organization') }}
      </h2>

      <!-- Left side - Image & info -->
      <div class="col-12 col-md-6">
        <div class="row">
          <!-- Image -->
          <div class="col-4">
            <img
              :src="item.organization.image"
              style="
                border-radius: 10px;
                object-fit: contain;
                max-height: 150px;
                width: 100%;
              "
            />

            <small class="image-copyright">
              &copy; {{ item.organization.name }}
            </small>
          </div>

          <!-- Info -->
          <div class="col-8">
            <h3 style="color: #2b2b2b; font-size: 20px">
              {{ item.organization.name }}
            </h3>
            <div class="mt-3">
              {{ item.organization.address + ', ' + item.organization.city }}
            </div>
            <div class="mt-3">
              {{ item.organization.email }}
            </div>
            <div class="mt-3">
              {{ item.organization.phone }}
            </div>
          </div>
        </div>
      </div>

      <!-- Right side - Persons -->
      <div class="col-12 col-md-6 mt-4 mt-md-0">
        <div class="row">
          <div
            v-for="(person, i) in item.organization.persons"
            :key="i"
            class="col-6 mb-3"
          >
            <div class="font-weight-bold">{{ person.name }}</div>
            <div class="mt-1">{{ person.email }}</div>
            <div class="mt-1">{{ person.phone }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Stories -->
    <div class="row mt-5">
      <h2 class="hty-heading col-12 mb-3">
        {{ $t('socialProjects.stories') }}
      </h2>

      <div
        v-for="(story, i) in item.organization.stories"
        class="col-6 mb-5"
        :key="i"
      >
        <div class="row">
          <div class="col-4">
            <img
              :src="story.image"
              style="
                border-radius: 10px;
                object-fit: cover;
                height: 120px;
                width: 100%;
              "
            />

            <small class="image-copyright">
              &copy; {{ item.organization.name }}
            </small>
          </div>
          <div class="col-8">
            <h4 class="text-orange" style="font-size: 18px">
              {{ story.title }}
            </h4>
            <p class="description mt-2">{{ story.description }}</p>
            <ButtonElement orange link @click="openStoryLink(story)">
              {{ $t('buttons.readMore') }}
            </ButtonElement>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="d-flex justify-content-between mt-3">
      <ButtonElement
        orange-secondary
        class="mr-1"
        style="width: 150px"
        @click="$router.push({ name: 'SocialProjects' })"
      >
        {{ $t('socialProjects.back') }}
      </ButtonElement>
      <ButtonElement
        class="ml-1"
        orange
        style="width: 150px"
        @click="donateModal = true"
      >
        {{ $t('socialProjects.donate') }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import 'vue-agile/dist/VueAgile.css';
import DonateModal from './DonateModal.vue';

const items = [
  {
    id: 1,
    title: 'Soforthilfe',
    description:
      '<p>Katastrophen und Konflikte lassen tausende von Menschen in den betroffenen Gebieten häufig vor dem Nichts stehen. Erdbeben, Überschwemmungen oder Stürme zerstören schnell ganze Regionen und Existenzen. In Situationen bewaffneter Konflikte leidet meist die Zivilbevölkerung unter der Gewalt und dem Mangel an Nahrung und Gesundheitsversorgung.</p>' +
      '<p>Aufgrund seiner sieben Grundsätze wie z.B. Neutralität und Unparteilichkeit hilft das Deutsche Rote Kreuz allen Menschen, die in Not geraten sind, etwa mit medizinischer Versorgung, Notunterkünften und Nahrungsmitteln, in über 40 Ländern. Auch dank Ihnen und Ihrer Spende.</p>' +
      '<p>Achtung: Wenn Sie eine Spendenbescheinigung für dieses Projekt erhalten möchten, tätigen Sie Ihre Spende bitte direkt über die Website des DRK unter: <a href="https://www.drk.de/hilfe-weltweit/" target="_blank">https://www.drk.de/hilfe-weltweit/</a></p>',
    goal_amount: null,
    goal_description:
      'Die Nothilfe des DRK lindert die humanitären Folgen von Krisen: Leben retten, Überleben sichern, das Wiederaufbauen ermöglichen.',
    images: [
      'https://i.ibb.co/WzKLtqS/Flchtlinge-in-Idomeni-Nordgriechenland-Das-DRK-baut-eine-mobile-Gesundheitsstation-fr-die-Flchtlinge.jpg',
    ],
    organization: {
      name: 'Deutsches Rotes Kreuz',
      address: 'Carstennstr. 58',
      city: '12205 Berlin',
      phone: '030 85 404 0',
      website: 'www.drk.de',
      image:
        'https://i.ibb.co/RctgDXp/Project-1-Organization-Information-Select-an-image-to-display.jpg',
      persons: [
        {
          name: 'Spendenservice',
          email: 'Spenderservice@DRK.de',
          phone: '030 / 85 404 - 444',
        },
      ],
      stories: [
        {
          title: 'Wir helfen weltweit',
          description:
            'Wir helfen weltweit – bei akuten Katastrophen und mit langfristigen Projekten.',
          link: 'https://www.drk.de/hilfe-weltweit/',
          image:
            'https://i.ibb.co/W3Xn4hG/csm-artikelbild-asien-hilfe-weltweit-460x270-3d498e6c0e.jpg',
        },
        {
          title: 'Wir helfen in Deutschland',
          description:
            'Wir helfen bei Ihnen vor Ort – kompetent, verlässlich, unkompliziert. Finden Sie hier unsere Angebote.',
          link: 'https://www.drk.de/hilfe-in-deutschland/',
          image:
            'https://i.ibb.co/4trSX0m/csm-drk-hilfe-in-deutschland-460x270-1c72feea7c.jpg',
        },
        {
          title: 'Spenden-Themen',
          description:
            'Ohne Ihre Hilfe können wir wenig ausrichten. Durch eine Spende kann jeder einen Beitrag leisten – ob Privatperson, Unternehmen oder Schulkasse.',
          link: 'https://www.drk.de/spenden/',
          image:
            'https://i.ibb.co/k0TNwC1/csm-spenden-fuer-das-drk-460x270-1118355151.jpg',
        },
        {
          title: 'Mitwirken und Bewirken',
          description:
            'Das DRK ist eine Gemeinschaft von Helfenden. Bringen Sie sich ein und werden Sie Teil dieser Bewegung. Jeder kann sich ehrenamtlich engagieren und mithelfen!',
          link: 'https://www.drk.de/mitwirken/',
          image:
            'https://i.ibb.co/27s0hwG/csm-drk-Artikelteaserbild-Mitwirken-Ehrenamt-Wohlfahts-und-Sozialarbei-460x270-40d5748053.jpg',
        },
      ],
    },
  },
  {
    id: 2,
    title:
      'Unterstützung des Mount-Rosary-Hospitals bei der Behandlung bedürftiger Patientinnen und Patienten',
    description:
      '<p>Im Mount-Rosary-Hospital werden seit 1937 bedürftige Menschen behandelt. Die Ordensschwestern von Mount Rosary (Helpers of Mount Rosary) haben vor wenigen Jahren das Hospital neu gebaut und die Behandlungsangebote deutlich erweitert. Wir unterstützen das Hospital bei seiner Gesundheitsarbeit seit 1990, zunächst über ein Hilfswerk und seit 1998 mit unserem Verein.</p>' +
      '<p>Für die Behandlung bedürftiger Patienten haben die Schwestern einen Hilfsfonds eingerichtet, den wir gern über HOODTY unterstützen möchten.</p>',
    goal_amount: 1000,
    goal_description:
      'Viele Menschen in Indien haben keine Krankenversicherung und können sich eine Behandlung nicht leisten. Durch die Unterstützung aus dem Hilfsfonds ist es möglich, diese Menschen zu behandeln. Gesundheit ist ein wichtiger Bestandteil der Lebensqualität. Außerdem versetzt sie die Menschen in die Lage, wieder den Lebensunterhalt für sich und ihre Familien zu verdienen.',
    images: [
      'https://i.ibb.co/mFNfsFX/Project-2-Project-Information-Add-images-to-display-1.jpg',
      'https://i.ibb.co/yn4TjTX/Project-2-Project-Information-Add-images-to-display-2.jpg',
      'https://i.ibb.co/yVWdgJH/Project-2-Project-Information-Add-images-to-display-3.jpg',
    ],
    organization: {
      name: 'Freunde von Mount Rosary',
      address: 'Neufeldheide 4',
      city: '47906 Kempen',
      phone: '02845 8857',
      website: 'www.mount-rosary.de',
      image:
        'https://i.ibb.co/bRYPzVK/Project-2-Organization-Information-Select-an-image-to-display.jpg',
      persons: [
        {
          name: 'Alfons Rasche',
          email: 'alfons.rasche@posteo.de',
          phone: '02845 8857',
        },
      ],
      stories: [
        {
          title: 'Über uns',
          description:
            'Unser Verein wurde 1998 in der Pfarrgemeinde Sankt Antonius Tönisberg (jetzt: St. Martinus Rheurdt-Schaephuysen-Tönisberg) in Kempen – Tönisberg gegründet.',
          link: 'https://www.mount-rosary.de/ueber-uns/',
          image: 'https://i.ibb.co/CQZN34K/cropped-LOGO-NEU-GELB-1.jpg',
        },
        {
          title: 'Unsere Partner in Indien',
          description:
            'Unsere Partner in Indien sind Monsignore Edwin C. Pinto und Schwester Prescilla D’Mello. Hier finden Sie einige Informationen über Monsognore Pinto.',
          link: 'https://www.mount-rosary.de/partner/',
          image:
            'https://i.ibb.co/yFjS44H/Monsignore-Pinto-Schwester-Prescilla-D-mello-300x225.jpg',
        },
        {
          title: 'Unsere Projekte',
          description: 'Hier sind Beispiele für unsere Hilfsprojekte.',
          link: 'https://www.mount-rosary.de/projekte/',
          image: 'https://i.ibb.co/bKxN3Cw/IMG-20210603-WA0001-300x224.jpg',
        },
        {
          title: 'Weitere Informationen',
          description:
            'Die Karitativen Einrichtungen von Mount Rosary liegen in Alangar/Moodbidri in der Nähe von Mangalore im Südwesten Indiens. Diese Einrichtungen bestehen seit 1937.',
          link: 'https://www.mount-rosary.de/infos/',
          image: 'https://i.ibb.co/y4xB7jf/009-006-768x576.jpg',
        },
      ],
    },
  },
];

export default {
  components: { DonateModal, VueAgile },
  data() {
    return {
      donateModal: false,
      item: false,
      isLoading: true,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      setTimeout(() => {
        this.item = items.find(i => i.id == this.$route.params.id);

        if (!this.item) {
          alert(404);
        } else {
          this.isLoading = false;
        }
      }, 500);
    },
    openStoryLink(story) {
      window.open(story.link, '_blank');
    },
  },
};
</script>

<style lang="scss"></style>
